@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

.container {
  max-width: 600px;
  margin: 0px auto;
  padding: 10px;
}

.panel {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 25px 0;
  background-color: #fff;
}

.btn {
  font-weight: 500;
  border: 1px solid #666;
  border-radius: 4px;
  background: #fff;
  transition: all 0.3s ease 0s;
  outline: none !important;
  padding: 6px 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.btn:active {
  transform: translateY(4px);
}

.btn:disabled {
  color: #777;
}

.text-center {
  text-align: center;
}

.form-horizontal {
  display: flex;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.wish {
  font-style: italic;
  font-size: 18px;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #fdce3e; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input,
textarea {
  padding: 7px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}
